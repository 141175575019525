export default {
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    outlined_class() {
      return this.outlined ? "border border-black text-black" : "text-white";
    },
  },
};
